<template>
    <div>
        <el-button size="mini" type="primary"  icon="el-icon-plus" @click="add()">添加</el-button>    
		<el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column align="center" prop="id" label="编号" sortable width="200"></el-table-column>
			<el-table-column align="center" label="收款类型" >
				<template slot-scope="scope" >
					<el-tag v-if="scope.row.status==1" type="success">支付宝</el-tag>
                    <el-tag v-if="scope.row.status==2" type="warning">银行卡</el-tag>
                </template>
			</el-table-column>
            <el-table-column align="center" prop="name" label="姓名" ></el-table-column>
			<el-table-column align="center" prop="account" label="收款账号" ></el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间" ></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button  @click="detail(scope.row.id)" type="text" size="small">编辑</el-button>
                    &nbsp;
					<el-popconfirm title="确定要删除这条数据吗" @confirm="deletes(scope.$index, data)">
						<el-button class="text-danger"  slot="reference"  type="text" size="small"><i class="el-icon-delete"></i>删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>
		
		<Modal v-model="router"  width="75%" :footer-hide="true" class-name="order-modal" @on-cancel="ModalCancel"    :mask="false" :title="ModalTitle">
		       <router-view @routerCancel="ModalCancel"></router-view>
		</Modal>
	</div>
</template>
<script>
	import {request} from "@/library/request.js";
	export default {
		data(){
			return{
				data:[],
				total:0,
				pageSize:15,
				router:false,
				currentPage:0,
                ModalTitle:"",
				loading: false,
			}
		},
        methods: {
            getList(){
				this.loading = true
				this.$Loading.start();
				var search = {
					limit:this.pageSize,
					page:this.currentPage,
				}
				return request({
					url:'withdrawal/list',
					method:"get",
					params:search
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.data = response.data.data
					this.total = response.data.total
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
            ModalCancel(res){
				this.router=false
				this.$router.push({
					path:'/withdrawal/list'
				})
				if(res){
					this.getList()
				}
			},
            add(){
				this.ModalTitle ="创建提现账号"
				this.router=true
				this.$router.push({
					path:'/withdrawal/create'
				})
			},
            detail(id){
                this.ModalTitle ="编辑提现账号"
				this.router=true
				this.$router.push({
					name:'withdrawal/edit',
                    params:{id:id}
				})
            },
            deletes(index,row){
				return request({
					url:'withdrawal/delete/'+row[index].id,
					method:"post",
				}).then(response  => {
					if(response.code==200){
						row.splice(index, 1);
					}else{
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				
			},
        },
        mounted() {
            var route =this.$route.path;
            if(route!="/withdrawal/list"){
                this.ModalCancel()
            }
            this.getList()
        }
    }
</script>
<style source>
	@import url('/css/app.css');

</style>